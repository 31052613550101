.headerSearch {
  display: flex;
  position: relative;
  width: 200px;
  border-bottom: 1px solid #ff4500;
  border-radius: 5px;
  span {
    position: absolute;
    right: 5px;
    top: -1px;
  }
  input {
    padding: 0 1rem;
    height: 24px;
    width: 100%;
    background-color: transparent;
    color: white;
  }
}
