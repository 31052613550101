.welcomePageSection {
  padding: 0 111px;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  img {
    height: 770px;
    overflow: hidden;
  }
  .welcomePageText {
    width: 500px;
    display: flex;
    gap: 20px;
    flex-direction: column;

    h1 {
      color: white;
      font-size: 90px;
      font-family: "Outfit", sans-serif;
      font-weight: bold;
    }
    p {
      color: #acacaa;
    }
  }

  .welcomePageButtons {
    padding-top: 10px;
    display: flex;
    gap: 25px;
    button {
      background-color: #fe5b57;
      color: white;
      padding: 20px 48px;
      font-size: 23px;
    }

    :last-child {
      background-color: #1f1f1f;
      color: #a4a29f;
      border-radius: 15px;
      font-size: 19px;
    }
  }
}
