.headerRegister {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #181818;
  padding: 20px;
  border-radius: 10px;
  z-index: 5;
  color: #aaa9a7;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  h2 {
    color: #fe5b57;
    font-size: 1.5rem;
  }

  .registerError {
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    color: red;
    font-size: 12px;
  }

  .buttonExit {
    display: flex;
    font-size: 2rem;
    color: white;
    right: 20px;
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    box-shadow: none;
  }
  .headerForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input {
      background-color: #292928;
      padding: 10px;
      border-radius: 5px;
    }
    button {
      background-color: #fe5b57;
      width: fit-content;
      padding: 10px 20px;
      color: white;
      border-radius: 5px;
    }
    label {
      font-weight: normal;
      font-size: 1rem;
      font-family: Arial, Helvetica, sans-serif;
    }
    .registerLoginButton {
      background-color: transparent;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
}
