.footer {
  background-color: #181818;
  width: 100%;
  color: white;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid #434241;
}
