.topAnimeSection {
  padding: 60px 111px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-family: "Outfit", sans-serif;
    font-size: 71px;
    color: white;
    padding-bottom: 60px;
    text-align: center;
  }

  .topAnimeItems {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;

    .topAnimeItem {
      color: #eeedee;
      height: 350px;
      background-color: #232323;
      border-radius: 15px;
      position: relative;
      box-sizing: border-box;
      border: 1px solid black;
      overflow: hidden;
      transition: all 0.3s;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #2323235e;
        z-index: 1;
        border-top-left-radius: 0;
        border-radius: 15px;
        transition: all 0.5s;
      }

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 76px;
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        z-index: 2;
        background-color: #232323d7;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 3px;
      }

      img {
        border-radius: 15px;
        object-fit: cover;
        height: 100%;
        transition: all 0.5s;
        position: absolute; /* Абсолютне позиціонування зображення */
        top: 50%; /* Вирівнюємо по вертикалі */
        left: 50%; /* Вирівнюємо по горизонталі */
        transform: translate(-50%, -50%);
      }

      &:hover {
        opacity: 0.8;
        cursor: pointer;
        img {
          transform: translate(-50%, -50%) scale(1.8);
        }

        &::after {
          background-color: #23232396;
        }
      }
    }
  }
}
