.sugestedAnime {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #a4a29f;
  max-width: 100vw;

  font-size: 16px;
  .sugestedAnimeText {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 600px;
    padding: 0 111px;
    h1 {
      color: white;
      font-size: 90px;
      font-family: "Outfit", sans-serif;
      font-weight: bold;
    }
    h4 {
      font-family: "Nunito";
    }
    p {
      color: #acacaa;
    }

    .watchingPageGenre {
      display: flex;
      gap: 5px;
      padding-top: 10px;
      span {
        font-size: 12px;
        background-color: #fe5b57;
        border-radius: 5px;
        border: 1px solid #a4a29f67;
        padding: 5px;
        display: flex;
        text-align: center;
        align-items: center;
      }
    }
    .sugestedAnimeYear {
      display: flex;
      gap: 15px;
      align-items: center;
      span {
        color: #fe5b57;
        font-family: monospace;
      }
    }
  }

  .sugestedAnimeImage {
    z-index: -1;
    position: relative;
    height: 700px;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      height: 700px;
      top: 0;
      left: 50;
      width: 100%;
      background: rgb(8, 8, 8);
      background: rgb(8, 8, 8);
      background: linear-gradient(
        90deg,
        rgba(8, 8, 8, 1) 0%,
        rgba(8, 8, 8, 0.8435749299719888) 23%,
        rgba(4, 4, 4, 0.33657212885154064) 87%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 45;
    }
    img {
      height: 100%;
    }
  }
}
