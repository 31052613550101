.registerModalBG {
  top: 0;
  right: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #00000077;
  z-index: 5;
}

.headerBg {
  background-color: #181818;
  border-bottom: 1px solid #434241;
  .header {
    margin: 0 111px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    color: #aaa9a7;
    font-size: 16px;

    font-size: 24px;
    font-weight: bold;

    .headerLogo {
      font-family: "Outfit", sans-serif;
      display: flex;
      color: white;
      font-family: "Outfit", sans-serif;
      font-size: 24px;
    }
    .headerRight {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;
      color: #aaa9a7;
      .headerNav {
        margin-left: 10vw;
        ul {
          display: flex;
          list-style-type: none;
          font-size: 14px;
          font-weight: 600;
          gap: 31px;
          li {
            cursor: pointer;
            &:hover {
              opacity: 80%;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
      .headerUserProfile {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 24px;
        span {
          color: #fe5b57;
          font-weight: 600;
        }
      }

      .headerLoginRegestration {
        display: flex;
        gap: 1rem;

        button {
          height: 2.5rem;
          width: 7rem;
          background-color: #fe5b57;
          border-radius: 5px;

          font-family: Righteous, sans-serif;
          color: white;
        }

        :last-child {
          background-color: #181818;
          border: 1px solid #434241;
          color: #aaa9a7;
          width: 5rem;
        }
      }
    }

    .headerProfileButton {
      background-color: transparent;
    }
  }
}
